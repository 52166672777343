/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'
import jsrsasign from 'jsrsasign'
// 时间添零
let getzf = (num) => {
  if (parseInt(num) < 10) {
    num = '0' + num;
  }
  return num;
}
// 时间格式化yyyyMMddHHmmss

// 当前时间戳

// var module = "rfid";
// var method = "updateinit";
var token = "rfid_device";
let privateKey = "-----BEGIN RSA PRIVATE KEY-----\n" +
  "MIIBPAIBAAJBAMbSHmViMWBOPlG5cFcsIlfTd07RtaxdEuyQxbccnLieUiI4i2V1\n" +
  "NYkDN7Pc+YuDjzZOnfoszTRmDxx3eo8jShcCAwEAAQJANLtqAG3ItshJ0xch6pz0\n" +
  "BuM5U5hfXPXanQ/QwL4/+t7bLvsh0+LMGWQhRXZ+m3HDFdLe7mB/2WuVjX+pyZ2U\n" +
  "sQIhAORkHNFLn0Iv6o9iE8/97sx6csHyie8LWB+ttmt3dQ0bAiEA3trpUPVUsuzu\n" +
  "cAkXqS+NiUWQrWILWBQ1RhrgLLxRcrUCIQC/c3B4vdSVrgcJBKUpEGtSOz7hd8fK\n" +
  "e472ly64rV2QiwIhALc1lRZfejBWpHcqom8OjvdJX3WK4vSYOGvX8HAWqYb5AiEA\n" +
  "g4vKPT1f6k+L7EXY0v7iIUJwn7yrzGp4RWhPd6WLZPw=\n" +
  "-----END RSA PRIVATE KEY-----";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址 
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: 'get',
      url: url
    }
    if (params) config.params = params
    return request(config)
  },
  post(url, params,to) {
    if(to){
      const config = {
        method: 'post',
        url: url,
        data : params
      }
      return request(config)
    }
    let ts = new Date().getTime();
    let time = () => {
      let date = new Date();
      return date.getFullYear().toString() + getzf(date.getMonth() + 1) + getzf(date.getDate()) + getzf(date.getHours()) + getzf(date.getMinutes()) + getzf(date.getSeconds());
    };
    let rsa = new jsrsasign.RSAKey();
    let event_time = time()
    let signstr = token + ts + event_time;
    rsa.readPrivateKeyFromPEMString(privateKey);
    let signature = rsa.sign(signstr, "sha1");
    const config = {
      method: 'post',
      url: url + "/1.1/" + ts + '/' + event_time + "/" + signature
    }
    if (params) config.data = params
    return request(config)
  },
}
//导出
export default http